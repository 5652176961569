import React from 'react';
import { Box, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { formsUrls } from '@config/routes';
import { formsMeta } from '@config/meta/forms/formsMeta';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { useParams } from 'react-router-dom';
import { createRecord } from '@config/functions/requests';
import DetailAccordionView from '@components/DetailAccordionView';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import {
  questionRowsMap,
  responseRowsMap,
  groupRowsMap,
  branchingConditionRowsMap,
} from '@config/handleRows/forms';
import {
  QuestionsForm,
  GroupForm,
  BranchingConditionForm,
  RecipientForm,
} from '@config/forms/forms';
import * as Yup from 'yup';
import { microservices } from '@config/constants';
import ImportGroupPopup from '@components/Forms/ImportGroupPopup';
import { v4 as uuid } from 'uuid';

const {
  formsListUrls,
  questionsUrls,
  responsesUrls,
  groupsUrls,
  branchingConditionsUrls,
  recipientsUrls,
} = formsUrls;

export default function FormDetail({ editMode, labels }) {
  const [notify] = useToast();
  const { id: recordId } = useParams();
  const user = useSelector(selectProfile);
  const [recordData, setRecordData] = React.useState(null);
  const [showImportGroup, setShowImportGroup] = React.useState(false);
  const [questionsListData, setQuestionsListData] = React.useState({});
  const [groupListData, setGroupListData] = React.useState({});
  const [copied, setCopied] = React.useState(false);
  const [branchingConditionCount, setBranchingConditionCount] =
    React.useState(1);

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback((data) => formsMeta({ data }), []);

  const groupColOptions = React.useMemo(
    () => ({
      formId: recordId,
      nextOrder: groupListData?.totalCount ?? 1,
    }),
    [recordId, groupListData?.totalCount]
  );

  const questionColOptions = React.useMemo(
    () => ({
      formId: recordId,
      nextOrder: questionsListData?.totalCount ?? 1,
    }),
    [recordId, questionsListData?.totalCount]
  );

  const branchingConditionColOptions = React.useMemo(
    () => ({
      nextOrder: branchingConditionCount,
      formId: recordId,
    }),
    [recordId, branchingConditionCount]
  );

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              { to: '/forms', text: 'Forms' },
              { text: recordData?.name },
            ]}
          >
            <Button
              type='button'
              variant='outlined'
              onClick={() => {
                navigator.clipboard.writeText(
                  `${window.location.host}/view-form/${recordId}`
                );
                setCopied(true);
                setTimeout(() => setCopied(false), 1000);
              }}
            >
              {copied ? 'Copied' : 'Copy Link'}
            </Button>
            <a
              href={`/view-form/${recordId}`}
              target='_blank'
              rel='noreferrer'
              style={{ textDecoration: 'none' }}
            >
              <Button type='button' variant='outlined' sx={{ ml: 1 }}>
                Preview
              </Button>
            </a>
          </DetailPageHeader>
        </Box>
      ) : null}

      <DetailCard
        nodeAPI
        urls={formsListUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
        getValues={(values) => {
          return {
            ...values,
            anonymous_can_see_it:
              values?.isPublished !== undefined
                ? !!values?.isPublished
                : undefined,
          };
        }}
      />

      <ImportGroupPopup
        user={user}
        open={showImportGroup}
        setOpen={setShowImportGroup}
        formId={recordId}
      />

      {recordData ? (
        <Box sx={{ mt: 5 }}>
          <DetailAccordionView
            nodeAPI
            editMode={editMode}
            labels={labels}
            label='Groups'
            prefix='0ud'
            importExportFields={`?formId=${recordId}`}
            microservice={microservices.Forms.name}
            model={microservices.Forms.models.group}
            columnKey='groupForms'
            columnOptions={groupColOptions}
            urls={groupsUrls}
            customOptionsOnRight={() => (
              <Button disableElevation onClick={() => setShowImportGroup(true)}>
                Import Group
              </Button>
            )}
            addNewForm={{
              getFields: ({ setFieldValue }) =>
                GroupForm({
                  formId: recordId,
                  formName: recordData?.name,
                  setFieldValue,
                }),
              handleSubmit: async (
                { parentGroup, ...values },
                setSubmitting,
                resetForm,
                setAddNewForm,
                refetch,
                setError
              ) => {
                try {
                  await createRecord({
                    values: {
                      ...values,
                      parentGroupId: parentGroup?.id,
                      formId: recordId,
                    },
                    url: groupsUrls.list(),
                    token: user.token,
                    actAs: user?.actAs,
                  });

                  resetForm();
                  setAddNewForm(false);
                  refetch();
                } catch (err) {
                  handleCreateFormErrors({ err, setError, notify, values });
                } finally {
                  setSubmitting(false);
                }
              },
            }}
            handleRowMap={groupRowsMap}
            customSortColumnName='order'
            sortColumnBy='asc'
            urlParams={`&formId=${recordId}`}
            getListData={(data) => setGroupListData(data)}
          />

          <DetailAccordionView
            nodeAPI
            editMode={editMode}
            labels={labels}
            label='Questions'
            prefix='0g9'
            microservice={microservices.Forms.name}
            model={microservices.Forms.models.question}
            columnKey='questions'
            columnOptions={questionColOptions}
            urls={questionsUrls}
            customInitialValues={{
              likertLabel1: 'Strongly disagree',
              likertLabel2: 'Disagree',
              likertLabel3: 'Neutral',
              likertLabel4: 'Agree',
              likertLabel5: 'Strongly agree',
            }}
            importExportFields={`?formId=${recordId}`}
            addNewForm={{
              getFields: ({ values, setFieldValue }) =>
                QuestionsForm({
                  setFieldValue,
                  values,
                  formId: recordId,
                }),
              handleSubmit: async (
                values,
                setSubmitting,
                resetForm,
                setAddNewForm,
                refetch,
                setError,
                setFieldError
              ) => {
                try {
                  const {
                    type,
                    group,
                    likertLabel1,
                    likertLabel2,
                    likertLabel3,
                    likertLabel4,
                    likertLabel5,
                    ...restValues
                  } = values;

                  if (!group?.id) {
                    setFieldError('group', 'Please select a group');
                    return;
                  }

                  const typeLikert = type === 'Likert';

                  if (typeLikert) {
                    try {
                      await Yup.object({
                        likertLabel1: Yup.string().required(),
                        likertLabel2: Yup.string().required(),
                        likertLabel3: Yup.string().required(),
                        likertLabel4: Yup.string().required(),
                        likertLabel5: Yup.string().required(),
                      }).validate({
                        likertLabel1,
                        likertLabel2,
                        likertLabel3,
                        likertLabel4,
                        likertLabel5,
                      });
                    } catch (err) {
                      setFieldError(err.path, 'Please enter likert labels');
                      return;
                    }
                  }

                  const likertLabels = [
                    likertLabel1,
                    likertLabel2,
                    likertLabel3,
                    likertLabel4,
                    likertLabel5,
                  ];

                  await createRecord({
                    values: {
                      ...restValues,
                      type,
                      formId: recordId,
                      groupId: group?.id,
                      anonymous_can_see_it: true,
                      likertLabels: typeLikert ? likertLabels : undefined,
                    },
                    url: questionsUrls.list(),
                    token: user.token,
                    actAs: user?.actAs,
                  });

                  resetForm();
                  setAddNewForm(false);
                  refetch();
                } catch (err) {
                  handleCreateFormErrors({ err, setError, notify, values });
                } finally {
                  setSubmitting(false);
                }
              },
            }}
            handleRowMap={questionRowsMap}
            urlParams={`&formId=${recordId}`}
            customSortColumnName='order'
            sortColumnBy='asc'
            getListData={(data) => setQuestionsListData(data)}
          />

          <DetailAccordionView
            nodeAPI
            editMode={editMode}
            labels={labels}
            label='Branching Condition'
            hideSearch
            importExportFields={`?formId=${recordId}`}
            prefix='0bh'
            microservice={microservices.Forms.name}
            model={microservices.Forms.models.branchingCondition}
            columnKey='branchingCondition'
            columnOptions={branchingConditionColOptions}
            urls={branchingConditionsUrls}
            addNewForm={{
              getFields: ({ values, setFieldValue }) =>
                BranchingConditionForm({
                  values,
                  formId: recordId,
                  formName: recordData?.name,
                  setFieldValue,
                }),
              handleSubmit: async (
                {
                  currentGroup,
                  sourceGroup,
                  targetGroup,
                  question,
                  questionOption,
                  questionOptionGroup,
                  ...values
                },
                setSubmitting,
                resetForm,
                setAddNewForm,
                refetch,
                setError,
                setFieldError
              ) => {
                try {
                  if (!currentGroup?.id) {
                    setFieldError('currentGroup', 'This field is required');
                    return;
                  }

                  if (!sourceGroup?.id) {
                    setFieldError('sourceGroup', 'Please select the source');
                    return;
                  }

                  if (!targetGroup?.id) {
                    setFieldError('targetGroup', 'Please select the target');
                    return;
                  }

                  if (!question?.id) {
                    setFieldError('question', 'Please select a question');
                    return;
                  }

                  if (
                    (question?.type === 'Date' ||
                      question?.type === 'Likert') &&
                    !values.staticValue
                  ) {
                    setFieldError('staticValue', 'This field is required.');
                    return;
                  }

                  if (
                    question?.type === 'MultipleChoice' &&
                    !questionOption?.id
                  ) {
                    setFieldError(
                      'questionOption',
                      'Please select a question option'
                    );
                    return;
                  }

                  await createRecord({
                    values: {
                      ...values,
                      currentGroupId: currentGroup?.id,
                      sourceGroupId: sourceGroup?.id,
                      targetGroupId: targetGroup?.id,
                      questionId: question?.id,
                      questionOptionId: questionOption?.id,
                      questionOptionGroupId: questionOptionGroup?.id,
                      formId: recordId,
                    },
                    url: branchingConditionsUrls.list(),
                    token: user.token,
                    actAs: user?.actAs,
                  });

                  resetForm();
                  setAddNewForm(false);
                  refetch();
                } catch (err) {
                  handleCreateFormErrors({ err, setError, notify, values });
                } finally {
                  setSubmitting(false);
                }
              },
            }}
            customSortColumnName='order'
            sortColumnBy='asc'
            handleRowMap={branchingConditionRowsMap}
            urlParams={`&formId=${recordId}`}
            getListData={(data) => {
              setBranchingConditionCount(data?.totalCount ?? 1);
            }}
          />

          <DetailAccordionView
            nodeAPI
            microservice={microservices.Forms.name}
            model={microservices.Forms.models.response}
            importExportFields={`?formId=${recordId}`}
            editMode={editMode}
            labels={labels}
            label='Responses'
            prefix='0pa'
            columnKey='responseForms'
            urls={responsesUrls}
            addNewForm={{
              getFields: ({ setFieldValue, values }) =>
                RecipientForm({
                  setFieldValue,
                  values,
                }),
              handleSubmit: async (
                values,
                setSubmitting,
                resetForm,
                setAddNewForm,
                refetch,
                setError,
                setFieldError
              ) => {
                try {
                  const {
                    personId,
                    emailTemplateId,
                    firstName,
                    lastName,
                    email,
                    expiryDate,
                    tags,
                  } = values;

                  if (!personId?.id) {
                    setFieldError('personId', 'This field is required');
                    return;
                  }

                  if (!emailTemplateId?.id && values?.sendEmail) {
                    setFieldError('emailTemplateId', 'This field is required');
                    return;
                  }

                  await createRecord({
                    values: {
                      tags,
                      firstName,
                      lastName,
                      email,
                      personId: personId?.id,
                      emailTemplateId: emailTemplateId?.id || uuid(),
                      withoutEmail: emailTemplateId?.id ? false : true,
                      expiryDate: expiryDate ?? undefined,
                      formId: recordId,
                      domainHost: window.location.origin,
                    },
                    url: recipientsUrls.list(),
                    token: user.token,
                    actAs: user?.actAs,
                  });
                  resetForm();
                  setAddNewForm(false);
                  refetch();
                } catch (err) {
                  handleCreateFormErrors({ err, setError, notify, values });
                } finally {
                  setSubmitting(false);
                }
              },
            }}
            handleRowMap={responseRowsMap}
            urlParams={`&formId=${recordId}`}
          />
        </Box>
      ) : null}
    </Box>
  );
}
